// PaceCalculationPage.js
import React, { useState } from 'react';
import {
  Typography, Grid, Box, Tab, Tabs, TextField, Button, Stack, Avatar
} from '@mui/material';
import PoolIcon from '@mui/icons-material/Pool';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { format, parseISO, isValid } from 'date-fns';

function PaceCalculationPage() {

  const formatToHHMMSS = () => new Date(0).toISOString().substring(11, 19); // Extracts HH:mm:ss
  const formatToMMSS = () => new Date(0).toISOString().substring(14, 19); // Extracts mm:ss


  const [currentSubTabIndex, setCurrentSubTabIndex] = useState(0);
  const [swimDistance, setSwimDistance] = useState("");
  const [swimTime, setSwimTime] = useState(formatToHHMMSS());
  const [swimPace, setSwimPace] = useState(formatToMMSS());
  const [bikeDistance, setBikeDistance] = useState("");
  const [bikeTime, setBikeTime] = useState(formatToHHMMSS());
  const [bikePace, setBikePace] = useState(null);
  const [runDistance, setRunDistance] = useState("");
  const [runTime, setRunTime] = useState(formatToHHMMSS());
  const [runPace, setRunPace] = useState(formatToMMSS());
  const [distanceUnit, setDistanceUnit] = useState("km");
  const [transition1, setTransition1] = useState(formatToMMSS());
  const [transition2, setTransition2] = useState(formatToMMSS());
  const [totalTime, setTotalTime] = useState(null);


  const [apiResponseHtml, setApiResponseHtml] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubTabChange = (event, subTabIndex) => {
    setCurrentSubTabIndex(subTabIndex);
    setSwimDistance("");
    setSwimTime(formatToHHMMSS());
    setSwimPace(formatToMMSS());
    setBikeDistance("");
    setBikeTime(formatToHHMMSS());
    setBikePace(null);
    setRunDistance("");
    setRunTime(formatToHHMMSS());
    setRunPace(formatToMMSS());
    setDistanceUnit("km");
    setTotalTime(null)
    setTransition1(formatToMMSS());
    setTransition2(formatToMMSS());
  };

  const handleSubmit = (event) => {
    event.preventDefault();
      handleTriathlonSubmit();
    // Add logic for other sub-tabs if necessary
  };

  
  const handleTriathlonSubmit = async () => {
    try {
        // Create form data object with the latest state values
    
        console.log("runtime before: ", runTime);

        const formData = {
          swimDistance,
          swimTime: swimTime || null,
          swimPace: swimPace || null,
          bikeDistance,
          bikeTime: bikeTime || null,
          bikePace: bikePace || null,
          runDistance,
          runTime: runTime || null,
          runPace: runPace || null,
          distanceUnit,
          transition1: transition1 || null,
          transition2: transition2 || null
      };

      const params = Object.fromEntries(
        Object.entries(formData).map(([key, value]) => [
            key, 
            value !== null ? value : "null"
          ])
      );
        //params.runTime = params.runTime === "null" ? params.runTime : formatDateToTimeString(params.runTime);
        params.swimPace = params.swimPace === "null" || !(params.swimPace instanceof Date) 
            ? params.swimPace 
            : formatPaceToString(params.swimPace);
        params.swimTime = params.swimTime === "null" || !(params.swimTime instanceof Date) 
            ? params.swimTime 
            : formatDateToTimeString(params.swimTime);
        params.bikeTime = params.bikeTime === "null" || !(params.bikeTime instanceof Date) 
            ? params.bikeTime 
            : formatDateToTimeString(params.bikeTime);
        params.runTime = params.runTime === "null" || !(params.runTime instanceof Date) 
            ? params.runTime 
            : formatDateToTimeString(params.runTime);
        params.runPace = params.runPace === "null" || !(params.runPace instanceof Date) 
            ? params.runPace 
            : formatPaceToString(params.runPace);
        console.log(params)
        // Make the API call
        const response = await axios.get(`${apiUrl}/pace-calc`, { params });
        console.log(response);


        // Update state with response data or retain existing values if response is missing data
        setBikeDistance(response.data.bikeDistance || bikeDistance);
        setBikeTime(parseTimeToTicks(response.data.bikeTime));
        setBikePace(response.data.bikePace || bikePace);
        setSwimDistance(response.data.swimDistance || swimDistance);
        setSwimTime(parseTimeToTicks(response.data.swimTime));
        setSwimPace(parsePaceToTicks(response.data.swimPace));
        setRunDistance(response.data.runDistance || runDistance);
        setRunTime(parseTimeToTicks(response.data.runTime));
        setRunPace(parsePaceToTicks(response.data.runPace));
        setDistanceUnit(response.data.distanceUnit || distanceUnit);
        setTransition1(parseTimeToTicks(response.data.transition1));
        setTransition2(parseTimeToTicks(response.data.transition2));

        setTotalTime(response.data.totalTime);


        // Display total time message in the console
        const totalTimeMessage = `Total race duration is: ${response.data.totalTime}`;
        console.log(totalTimeMessage);

    } catch (error) {
        console.error("API error:", error);
        setErrorMessage("Error: " + error.message);
    }

    // Optionally reset some states here if needed
    console.log("Triathlon form submitted!");
};

  const parseDate = (dateString) => {
    if (typeof dateString === 'string') {
      return parseISO(dateString);
    }
    return dateString;
  };

  const handleTimeChange = (newValue, setter) => {
  if (newValue && isValid(parseDate(newValue))) {
    const formattedTime = format(parseDate(newValue), "HH:mm:ss");
    setter(formattedTime);
  } else {
    setter(null);
  }
  };

  const handlePaceChange = (newValue, setter) => {
  if (newValue && isValid(parseDate(newValue))) {
    const formattedPace = format(parseDate(newValue), "mm:ss");
    setter(formattedPace);
  } else {
    setter(null);
  }
  };

  const handleSwimTimeChange = (newValue) => handleTimeChange(newValue, setSwimTime);
  const handleSwimPaceChange = (newValue) => handlePaceChange(newValue, setSwimPace);
  const handleBikeTimeChange = (newValue) => handleTimeChange(newValue, setBikeTime);
  const handleRunTimeChange = (newValue) => handleTimeChange(newValue, setRunTime);
  const handleRunPaceChange = (newValue) => handlePaceChange(newValue, setRunPace);

  const handleSwimDistanceChange = (event) => setSwimDistance(event.target.value);
  const handleBikeDistanceChange = (event) => setBikeDistance(event.target.value);
  const handleRunDistanceChange = (event) => setRunDistance(event.target.value);
  const handleBikePaceChange = (event) => setBikePace(event.target.value);
  const handleTransition1 = (event) => setTransition1(event ? format(event, "mm:ss") : null);
  const handleTransition2 = (event) => setTransition2(event ? format(event, "mm:ss") : null);

  // Improved Time Parsing and Formatting Functions
const parseTimeToTicks = (timeString) => {
  if (!timeString) return null;
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  const date = new Date();
  date.setHours(hours || 0, minutes || 0, seconds || 0, 0);
  return date;
};

const parsePaceToTicks = (timeString) => {
  if (!timeString) return null;
  const [minutes, seconds] = timeString.split(':').map(Number);
  const date = new Date();
  date.setMinutes(minutes || 0, seconds || 0, 0);

  return date;
};

const formatDateToTimeString = (dateStr) => {
  if (!dateStr) return "00:00:00";
  const date = new Date(dateStr);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

const formatPaceToString = (dateStr) => {
  if (!dateStr) return "00:00";
  const date = new Date(dateStr);
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${minutes}:${seconds}`;
};

  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
        <Tabs
          value={currentSubTabIndex}
          onChange={handleSubTabChange}
          orientation="horizontal"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Triathlon" />
          <Tab label="Run" />
          <Tab label="Bike" />
          <Tab label="Swim" />
        </Tabs>

        {currentSubTabIndex === 0 && (
          <Box sx={{ p: 3, maxWidth: 1200, mx: 'auto' }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                {/* Distance and Date Section */}
                <Grid item xs={12}>
                  <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                    <Typography variant="h5" component="div">Pace Details</Typography>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={5}>
                        <Typography variant="h6" gutterBottom>Distance</Typography>
                        <Stack spacing={2}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <PoolIcon />
                            <TextField
                              label="Swim (km)"
                              type="number"
                              value={swimDistance}
                              onChange={handleSwimDistanceChange}
                              inputProps={{ step: "0.2" }}
                              variant="outlined"
                              margin="normal"
                            />
                          </Stack>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <DirectionsBikeIcon />
                            <TextField
                              label="Bike (km)"
                              type="number"
                              value={bikeDistance}
                              onChange={handleBikeDistanceChange}
                              inputProps={{ step: "0.2" }}
                              variant="outlined"
                              margin="normal"
                            />
                          </Stack>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <DirectionsRunIcon />
                            <TextField
                              label="Run (km)"
                              type="number"
                              value={runDistance}
                              onChange={handleRunDistanceChange}
                              inputProps={{ step: "0.2" }}
                              variant="outlined"
                              margin="normal"
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                      {/* Date and Time Section */}
                      <Grid item xs={12} md={7}>
                        <Typography variant="h6" gutterBottom>Target Time</Typography>
                        <Stack spacing={2}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <PoolIcon />
                              <TimePicker
                                label="Swim (hh:mm:ss)"
                                value={swimTime}
                                onChange={handleSwimTimeChange}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                ampm={false} // Disable AM/PM
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss" // Ensure the format is hh:mm:ss
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <DirectionsBikeIcon />
                              <TimePicker
                                label="Bike (hh:mm:ss)"
                                value={bikeTime}
                                onChange={handleBikeTimeChange}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                ampm={false} // Disable AM/PM
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss" // Ensure the format is hh:mm:ss
                            />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <DirectionsRunIcon />
                              <TimePicker
                                label="Run (hh:mm:ss)"
                                value={runTime}
                                onChange={handleRunTimeChange}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                ampm={false} // Disable AM/PM
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss" // Ensure the format is hh:mm:ss
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography variant="h6" gutterBottom>Target Pace</Typography>
                        <Stack spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <PoolIcon />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        label="Swim (mm:ss)"
                                        value={swimPace}
                                        onChange={handleSwimPaceChange}
                                        views={['minutes', 'seconds']} format="mm:ss"
                                        renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" fullWidth />}
                                        ampm={false} // Disable AM/PM
                                    />
                                    
                                </LocalizationProvider>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <DirectionsBikeIcon />
                                <TextField
                                    label="Bike (km/h)"
                                    type="number"
                                    value={bikePace}
                                    onChange={handleBikePaceChange}
                                    inputProps={{ step: "0.2" }} // Allows decimal input
                                    variant="outlined"
                                    margin="normal"
                                    //fullWidth
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <DirectionsRunIcon />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        label="Run (mm:ss)"
                                        value={runPace}
                                        onChange={handleRunPaceChange}
                                        views={['minutes', 'seconds']} format="mm:ss"
                                        renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" fullWidth />}
                                        ampm={false} // Disable AM/PM
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography variant="h6" gutterBottom>Transition</Typography>
                        <Stack spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar sx={{ bgcolor: 'white', color: 'black', width: 24, height: 24, fontSize: 14, fontWeight: 'bold' }}>T1</Avatar>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        label="Transition 1 (mm:ss)"
                                        value={transition1}
                                        onChange={handleTransition1}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        ampm={false} // Disable AM/PM
                                        views={['minutes', 'seconds']}
                                        format="mm:ss" // Ensure the format is hh:mm:ss
                                    />
                                </LocalizationProvider>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar sx={{ bgcolor: 'white', color: 'black', width: 24, height: 24, fontSize: 14, fontWeight: 'bold' }}>T2</Avatar>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        label="Transition 2 (mm:ss)"
                                        value={transition2}
                                        onChange={handleTransition2}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        ampm={false} // Disable AM/PM
                                        views={['minutes', 'seconds']}
                                        format="mm:ss" // Ensure the format is hh:mm:ss
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {totalTime && (
                  <p>Total time is {totalTime}</p>
                )}
                {/* Form Submit Button */}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Calculate Pace
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
        {currentSubTabIndex === 1 && (
          <Box sx={{ p: 3, maxWidth: 1200, mx: 'auto' }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                {/* Distance and Date Section */}
                <Grid item xs={12}>
                  <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                    <Typography variant="h5" component="div">Pace Details</Typography>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={5}>
                        <Typography variant="h6" gutterBottom>Distance</Typography>
                        <Stack spacing={2}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <DirectionsRunIcon />
                            <TextField
                              label="Run (km)"
                              type="number"
                              value={runDistance}
                              onChange={handleRunDistanceChange}
                              inputProps={{ step: "0.2" }}
                              variant="outlined"
                              margin="normal"
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                      {/* Date and Time Section */}
                      <Grid item xs={12} md={7}>
                        <Typography variant="h6" gutterBottom>Target Time</Typography>
                        <Stack spacing={2}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <DirectionsRunIcon />
                              <TimePicker
                                label="Run (hh:mm:ss)"
                                value={runTime || null}
                                onChange={(newValue) => handleRunTimeChange(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                ampm={false} // Disable AM/PM
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss" // Ensure the format is hh:mm:ss
                                clearable // Allows clearing the value
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography variant="h6" gutterBottom>Target Pace</Typography>
                        <Stack spacing={2}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                              <DirectionsRunIcon />
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <TimePicker
                                      label="Run (mm:ss)"
                                      value={runPace || null} // Ensure value is null if empty
                                      onChange={(newValue) => handleRunPaceChange(newValue)}
                                      views={['minutes', 'seconds']} 
                                      format="mm:ss"
                                      renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" fullWidth />}
                                      ampm={false} // Disable AM/PM
                                      clearable // Allows clearing the value
                                  />
                              </LocalizationProvider>
                          </Stack>
                        </Stack>
                    </Grid>
                    </Grid>
                  </Box>
                </Grid>

                {/* Form Submit Button */}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Calculate Pace
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
        {currentSubTabIndex === 2 && (
          <Box sx={{ p: 3, maxWidth: 1200, mx: 'auto' }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                {/* Distance and Date Section */}
                <Grid item xs={12}>
                  <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                    <Typography variant="h5" component="div">Pace Details</Typography>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={5}>
                        <Typography variant="h6" gutterBottom>Distance</Typography>
                        <Stack spacing={2}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <DirectionsBikeIcon />
                            <TextField
                              label="Bike (km)"
                              type="number"
                              value={bikeDistance}
                              onChange={handleBikeDistanceChange}
                              inputProps={{ step: "0.2" }}
                              variant="outlined"
                              margin="normal"
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                      {/* Date and Time Section */}
                      <Grid item xs={12} md={7}>
                        <Typography variant="h6" gutterBottom>Target Time</Typography>
                        <Stack spacing={2}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <DirectionsBikeIcon />
                              <TimePicker
                                label="Bike (hh:mm:ss)"
                                value={bikeTime}
                                onChange={handleBikeTimeChange}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                ampm={false} // Disable AM/PM
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss" // Ensure the format is hh:mm:ss
                             />
                            </Stack>
                          </LocalizationProvider>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography variant="h6" gutterBottom>Target Pace</Typography>
                        <Stack spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <DirectionsBikeIcon />
                                <TextField
                                    label="Bike (km/h)"
                                    type="number"
                                    value={bikePace}
                                    onChange={handleBikePaceChange}
                                    inputProps={{ step: "0.2" }} // Allows decimal input
                                    variant="outlined"
                                    margin="normal"
                                    //fullWidth
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                    </Grid>
                  </Box>
                </Grid>

                {/* Form Submit Button */}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Calculate Pace
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
        {currentSubTabIndex === 3 && (
          <Box sx={{ p: 3, maxWidth: 1200, mx: 'auto' }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                {/* Distance and Date Section */}
                <Grid item xs={12}>
                  <Box sx={{ p: 2, border: '1px solid', borderColor: 'grey.300', borderRadius: 2 }}>
                    <Typography variant="h5" component="div">Pace Details</Typography>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={5}>
                        <Typography variant="h6" gutterBottom>Distance</Typography>
                        <Stack spacing={2}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <PoolIcon />
                            <TextField
                              label="Swim (km)"
                              type="number"
                              value={swimDistance}
                              onChange={handleSwimDistanceChange}
                              inputProps={{ step: "0.2" }}
                              variant="outlined"
                              margin="normal"
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                      {/* Date and Time Section */}
                      <Grid item xs={12} md={7}>
                        <Typography variant="h6" gutterBottom>Target Time</Typography>
                        <Stack spacing={2}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <PoolIcon />
                              <TimePicker
                                label="Swim (hh:mm:ss)"
                                value={swimTime}
                                onChange={handleSwimTimeChange}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                ampm={false} // Disable AM/PM
                                views={['hours', 'minutes', 'seconds']}
                                format="HH:mm:ss" // Ensure the format is hh:mm:ss
                                />
                            </Stack>
                          </LocalizationProvider>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Typography variant="h6" gutterBottom>Target Pace</Typography>
                        <Stack spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <PoolIcon />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        label="Swim (mm:ss)"
                                        value={swimPace}
                                        onChange={handleSwimPaceChange}
                                        views={['minutes', 'seconds']} format="mm:ss"
                                        renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" fullWidth />}
                                        ampm={false} // Disable AM/PM
                                    />
                                    
                                </LocalizationProvider>
                            </Stack>
                        </Stack>
                    </Grid>
                    </Grid>
                  </Box>
                </Grid>

                {/* Form Submit Button */}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Calculate Pace
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default PaceCalculationPage;